import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

export default class HakkoTesuryoIkkatsuSakuseiIndexEnableMakeButton {

    static initComponent(hakkoTesuryoIkkatsuSakuseiIndexElement: HTMLElement) {
        hakkoTesuryoIkkatsuSakuseiIndexElement.querySelectorAll<HTMLSelectElement>("[data-action=HakkoTesuryoIkkatsuSakuseiIndexEnableMakeButton]").forEach((element) => {
            new HakkoTesuryoIkkatsuSakuseiIndexEnableMakeButton(element, hakkoTesuryoIkkatsuSakuseiIndexElement);
        });
    }

    private hakkoTesuryoIkkatsuSakuseiIndexEnableMakeButtonElement: HTMLSelectElement;
    private parentElement: HTMLElement;

    private constructor(hakkoTesuryoIkkatsuSakuseiIndexEnableMakeButtonElement: HTMLSelectElement, parentElement: HTMLElement) {
        this.hakkoTesuryoIkkatsuSakuseiIndexEnableMakeButtonElement = hakkoTesuryoIkkatsuSakuseiIndexEnableMakeButtonElement;
        this.parentElement = parentElement;

        this.hakkoTesuryoIkkatsuSakuseiIndexEnableMakeButtonElement.addEventListener("change", (event) => this.handleChange(event));
    }

    private handleChange(event: Event): any {

        let enable = StringUtils.require(this.hakkoTesuryoIkkatsuSakuseiIndexEnableMakeButtonElement.selectedOptions[0].dataset.enableMakeButton) === "true";
        let makeButton = ObjectUtils.require(this.parentElement.querySelector<HTMLElement>("#makeButton"), HTMLElement);

        if (enable) {
            makeButton.classList.remove("disabled");
        } else {
            makeButton.classList.add("disabled");
        }
    }
}
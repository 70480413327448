const bootstrap = require('bootstrap');

import "./nyukinkeshikomi/NyukinkeshikomiIndex";
import "./omutsu-kyufu-tokuisaki";
import "./seikyusho-index";
import "./select-uriage-dialog";
import "./uriage-edit";
import "./uriage-index";
import "./uriage-new";

import HakkoTesuryoIkkatsuSakuseiIndex from "./hakkotesuryoikkatsusakusei/HakkoTesuryoIkkatsuSakuseiIndex";
import KojinmukeShimeSeikyushoIndex from "./kojinmukeshimeseikyusho/KojinmukeShimeSeikyushoIndex";
import MitsumorishoEdit from "./mitsumorisho/MitsumorishoEdit";
import MitsumorishoNew from "./mitsumorisho/MitsumorishoNew";

window.addEventListener('DOMContentLoaded', (event) => {
    HakkoTesuryoIkkatsuSakuseiIndex.initComponent();
    KojinmukeShimeSeikyushoIndex.initComponent();
    MitsumorishoEdit.initComponent();
    MitsumorishoNew.initComponent();

    document.querySelectorAll('.toast').forEach((el) => {
        const toast = new bootstrap.Toast(el, {});
        toast.show();
    });
});

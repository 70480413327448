import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import HakkoTesuryoIkkatsuSakuseiIndexEnableMakeButton from "./HakkoTesuryoIkkatsuSakuseiIndexEnableMakeButton";

export default class HakkoTesuryoIkkatsuSakuseiIndex {

    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=HakkoTesuryoIkkatsuSakuseiIndex]").forEach((element) => {
            new HakkoTesuryoIkkatsuSakuseiIndex(element);
        });
    }

    private hakkoTesuryoIkkatsuSakuseiIndexElement: HTMLElement;

    private constructor(hakkoTesuryoIkkatsuSakuseiIndexElement: HTMLElement) {
        this.hakkoTesuryoIkkatsuSakuseiIndexElement = hakkoTesuryoIkkatsuSakuseiIndexElement;

        HakkoTesuryoIkkatsuSakuseiIndexEnableMakeButton.initComponent(this.hakkoTesuryoIkkatsuSakuseiIndexElement);
        ConfirmSubmitButton.initComponent(this.hakkoTesuryoIkkatsuSakuseiIndexElement);
    }
}